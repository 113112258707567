.file .progress {
  background-color: rgba(80, 80, 120, 0.8);
  position: absolute;
  inset: 0;
  width: 0;
  padding-top: 10px;
  text-align: center;
}
.file .progress.done {
  top: 100px;
}

.file {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background-color: #41415d;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

button.btnLogin {
  height: 40px;
  color: #fff;
  border-color: #fff;
}

button.btnLogin:hover {
  border-color: #fff;
}

.username-cell span .MuiBadge-badge {
  right: -38px;
  color: white;
  font-weight: bold;
}

.selectField.MuiFormControl-root fieldset {
  border-color: #fff;
}
.selectField.MuiFormControl-root svg {
  fill: #fff;
}

.selectField.MuiFormControl-root .MuiOutlinedInput-root:hover fieldset,
.selectField.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #fff;
}

.selectField.MuiFormControl-root .MuiInputLabel-root.Mui-focused {
  color: #fff;
}

/* 
input[type="date"]:focus::-webkit-calendar-picker-indicator {
  border: none;
}

input[type="date"]:focus {
  outline: none;
  border: 2px solid blue;
} */

.date-picker{
  padding: 13px 32px 13px 11px !important;
  font: 16px Arial;
  color: #000000DE;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
.react-datepicker__input-container .react-datepicker__calendar-icon{
  right: 0px;
  top: 2px;
  pointer-events: none;

}
.date-border{
  border: 1px solid #ff0000 !important;
  outline: 0 !important;
}

.date-outline{
  border: 0;
  outline: #b8b8b8 solid 1px;
}

.error-message{
  width: 100%;
  padding-left: 16px;
  color: #FF4E3E;
  font-family: "Arial", sans-serif, "Roboto", "Helvetica" !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1.66 !important;
  text-align: left !important;
  margin-top: 3px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.customTable :where(th,td){
  padding: 5px 10px !important;

} 
.customTable thead th{
  line-height: 1.3 !important;
}
.customTable thead th span{
  
}
.customTable thead th svg{
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   left: calc(100% + 5px);
   margin: 0;
   width: 12px;
   height: 12px; 
}

.customTable .dayChip{
  width: 28px;
  height: 28px;
  font-size: 12px;
  background-color: #7742e621;
  color: #000000DE;
  text-transform: capitalize;
  border-radius: 50%;
  line-height: 1;
}
.customTable .dayChip span{
  padding-inline: 0 !important;

}